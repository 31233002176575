'use client';
import Loading from '@/components/Loading';
import { Spin } from 'antd';

const Page = () => (
    <div className='w-full h-[100vh] flex justify-center items-center'>
        {/* <Loading forceLoading={true} className='!static !bg-transparent' /> */}
        <Spin size='large' />
    </div>
);

export default Page;
